import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StyledLink } from "../components/utils"
import { media } from "../styles/theme"
import { urls } from "../urls"
import { contentfulRenderOptions } from "../utils"

const S = {}

S.BlogList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem 1rem;

  ${media.tablet`
    gap: 2rem 2rem;
    flex: 50%;
  `}
`

S.BlogListItem = styled.div`
  flex: auto;
  position: relative;
  width: 100%;

  ${media.tablet`
    flex: 45%;
  `}

  ${media.laptopL`
    flex: 31%;
  `}
`

S.PostDate = styled.div`
  font-family: ${props => props.theme.fonts.body};
  font-size: 14px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 300;
`

const BlogIndexPage = () => {
  const { allContentfulBlogPost } = useStaticQuery(
    graphql`
      {
        allContentfulBlogPost(
          sort: { order: DESC, fields: publicationDate }
          filter: { node_locale: { eq: "pl" } }
        ) {
          edges {
            node {
              id
              title
              createdAt
              publicationDate
              heroImage {
                gatsbyImage(
                  layout: CONSTRAINED
                  width: 1386
                  height: 924
                  placeholder: BLURRED
                )
              }
              intro {
                raw
              }
              url
            }
          }
        }
      }
    `
  )

  const jsonLdDataWebsite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://kamiko.studio" + urls.BLOG,
    name: "Blog",
  }

  const jsonLdBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: "https://kamiko.studio/",
        name: "Kamiko",
      },
      {
        "@type": "ListItem",
        position: 2,
        item: "https://kamiko.studio" + urls.BLOG,
        name: "Blog",
      },
    ],
  }

  const renderListItem = node => {
    let renderedIntro = null
    try {
      renderedIntro = documentToReactComponents(
        JSON.parse(node.intro.raw),
        contentfulRenderOptions
      )
    } catch (e) {}
    return (
      <S.BlogListItem key={node.id}>
        {node.heroImage && (
          <Link to={node.url}>
            <GatsbyImage alt={node.title} image={node.heroImage.gatsbyImage} />
          </Link>
        )}
        <h2>
          <Link to={node.url}>{node.title}</Link>
        </h2>
        <S.PostDate>
          {new Date(node.publicationDate).toLocaleDateString("pl-PL")}
        </S.PostDate>
        {renderedIntro && (
          <div>
            <Link to={node.url}>{renderedIntro}</Link>
          </div>
        )}
        <div>
          <StyledLink to={node.url}>Czytaj całość</StyledLink>
        </div>
      </S.BlogListItem>
    )
  }

  return (
    <Layout>
      <Seo
        title="Blog"
        url={urls.BLOG}
        jsonLdData={[jsonLdDataWebsite, jsonLdBreadcrumbs]}
      />
      <S.BlogList>
        {allContentfulBlogPost.edges.map(({ node }) => renderListItem(node))}
      </S.BlogList>
    </Layout>
  )
}

export default BlogIndexPage
