import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import { StyledA } from "./components/utils"

import styled from "styled-components"

export const makeProjectDescription = project =>
  `${project.type}, ${project.area}m2, ${project.place}, ${project.date} `

const S = {}

S.BlogAsset = styled.div`
  margin: 1.5rem 0;
`

S.VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const contentfulRenderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <StyledA
        style={{ textDecoration: "underline" }}
        href={data.uri}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </StyledA>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { iframe } = node.data.target
      return (
        <S.VideoContainer dangerouslySetInnerHTML={{ __html: iframe.iframe }} />
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { gatsbyImageData } = node.data.target
      if (!gatsbyImageData) {
        return null
      }
      const description = node.data.target.description || ""
      return (
        <S.BlogAsset>
          <GatsbyImage alt={description} image={gatsbyImageData} />
          <p
            style={{ fontStyle: "italic", fontSize: "14px", margin: "0.5em 0" }}
          >
            {description}
          </p>
        </S.BlogAsset>
      )
    },
  },
}
